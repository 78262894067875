export const formatPostal = (value='') => {
    let postalValue = value.toUpperCase();
    postalValue = postalValue.replace(/[^A-Za-z0-9]/g, '');
    if (postalValue.length > 3) {
        postalValue = postalValue.slice(0, 3) + ' ' + postalValue.slice(3);
    }

    let formattedValue = '';
    if (postalValue.length >= 1) formattedValue += postalValue[0].match(/[Tt]/) ? postalValue[0] : '';
    if (postalValue.length >= 2) formattedValue += postalValue[1].match(/\d/) ? postalValue[1] : '';
    if (postalValue.length >= 3) formattedValue += postalValue[2].match(/[A-Za-z]/) ? postalValue[2] : '';
    if (postalValue.length >= 4) formattedValue += '-';
    if (postalValue.length >= 5) formattedValue += postalValue[4].match(/\d/) ? postalValue[4] : '';
    if (postalValue.length >= 6) formattedValue += postalValue[5].match(/[A-Za-z]/) ? postalValue[5] : '';
    if (postalValue.length >= 7) formattedValue += postalValue[6].match(/\d/) ? postalValue[6] : '';

    return formattedValue;
}


export const validatePostal=(postalCode)=>{
    // Regular expression for Canadian postal code format A1A-1A1
    const regex = /^[A-Z]\d[A-Z]-\d[A-Z]\d$/;
    // Test the postal code against the regular expression
    return regex.test(postalCode);
      
}
