import React, { useEffect, useState } from 'react';
import css from './SelectQty.module.css';
import { SlOptionsVertical } from "react-icons/sl";
import { SlOptions } from "react-icons/sl";
import ItemList from "./ItemList/ItemList";
import Select from "./Select/Select";

const SelectQty =({
     equipmentType, 
     checkWithBasket, 
     basket, 
     setBasket, 
     setModifiedModel, 
     isVisible, 
     setIsVisible, 
     setComponentIndex, setTypeIndex, setView, setModelIndex=0,})=>{
    const [value, setValue] = useState();
    const [visibility, setVisibility] = useState(false);
    useEffect(()=>{setValue(checkWithBasket(equipmentType).length)})//update on every render
    useEffect(()=>updateVisibility())
    

    //on render sets visibility if this category is in isVisible array if not it sets new object with visiblity set to false.
    const updateVisibility=()=>{
        if(!!isVisible.find(i=>i.cat===equipmentType.title))
        {
            setVisibility((isVisible.find(i=>i.cat===equipmentType.title)).visibility)
           
        }
        else
        {
            setIsVisible([...isVisible, {cat:equipmentType.title, visibility:false}])
        }
    }


    //When user clicks on ... this method shows/hides item list 
    const toggleVisibility = () => {
        // Check if the isVisible array is not empty
        if (isVisible.length !== 0) {
            const updatedVisibleState = isVisible.map(item => {
                // If the category matches equipmentType.title, update the visibility to true
                if (item.cat === equipmentType.title) {
                    return { ...item, visibility: !visibility };
                }
                return item; // Return the item unchanged if category doesn't match
            });
    
            setIsVisible(updatedVisibleState); // Update the isVisible state with the updated visibility values
        }
        setVisibility(!visibility); // Toggle the visibility state maintained by this component
    };
    

    //runs when user selects a new qty
    //sets value of locatState and updates basket if new qty is higher adds default object to basket array if lower removes
    //all categry from basket slides to new qty and sets basket with qty and remaining elemtnets of basket
    const updateQty = (val) => {
        setValue(val);
        updateBasket(val);
        if(val === 0 && visibility)
        {
            toggleVisibility();
        } 
        
    };

  
    //deside based on new qty to add or remove item from basket
    const updateBasket=(val)=>{
        if(value !== val){
            value < val ? addToBasket(val):removeFromBasket(val);
        }
    }

    //adds element with default value to basket 
    const addToBasket= (
        val, location='', 
        model=equipmentType.models[0].model, 
        sku=equipmentType.models[0].sku, 
        radioName=equipmentType.radioName, 
        displayNoQty = equipmentType.displayNoQty,
        price=equipmentType.models[0].price,
        monthly=equipmentType.models[0].monthly,
        checkListKeyword=equipmentType.models[0].checkListKeyword,
        group= equipmentType.models[0].group,
        isService=equipmentType.models[0].isService,
        )=>{
        let newItem = [];
        for (let i = value; i < val; i++) {
            let obj = {
                cat: equipmentType.title,
                model: model,
                sku: sku,
                price: price,
                checkListKeyword:checkListKeyword,
                
            };
            equipmentType.fixedLocation?obj.loc= equipmentType.fixedLocation:obj.loc=location;
            
            if(group)
            {
                obj.group = group;
            }
            if(monthly)
            {
                obj.monthly = monthly;
            }
            if(isService)
                {
                    obj.isService = isService;
                }
            if(displayNoQty)
            {
                obj.displayNoQty = displayNoQty;
            }
            //Add to boject in the basket property radio name if radioName exists
            if (radioName) {
                obj.radioName = radioName;
                if(basket.find((obj)=>obj.radioName === radioName))
                {
                    //disable visibility of previus selected radio
                    //scan basket to find obj with radioName === roadiName
                    let prevObjWithSameRadioName = basket.find((obj)=>obj.radioName === radioName)
                    //find prevObjWithSamaeRaioName in isVisible array
                    let prevObjWithVisibility = isVisible.find((obj)=>obj.cat === prevObjWithSameRadioName.cat)
                    //disable visibility in prev selected radio obj
                    //if prev obj visibility was true: disable it and update
                    //otherweise no need to update
                    if(prevObjWithVisibility.visibility){
                        prevObjWithVisibility.visibility = false;
                        //update visibility
                        setVisibility([...isVisible, prevObjWithVisibility])
                    }
                }
            
               
                //if new radioName is coming remove from basket object with the same radio name if any and setBasket with remaining objects.
                setBasket(basket.filter((obj)=>obj.radioName !== radioName))
            }
            newItem.push(obj);
        }
        setBasket(basket => [...basket, ...newItem]);     
    }

   
    
    //removes item from basket 
    //first seperate all same category and places in array then removed from the end and sets new basket with remaining elements
    const removeFromBasket=(val)=>{
        let items = checkWithBasket(equipmentType);
        let filteredBasket = basket.filter(i=>i.cat !== items[0].cat)
        const n = value- val;
        setBasket([...filteredBasket, ...items.slice(0, -n)])
    }

    //it is executed by ListBody when user types location in text input.
    //array with current category items is seperated from the rest of the basket elements and passed down to ListBody along with updateBasketItemDetails method.
    //ListBody uses this array to display values if eny.
    //It also make a copy of basket, updates loc with new user value and retruns as itmes attributte of updateBasketItemDetails method.
    //Here the current cattegory is seperated from the basket, replaced with a new updates items and re-joined with remaining of orginal basket.
    //basket is updated with user values.
    const updateBasketItemsDetails=(items)=>{
        let filteredBasket = basket.filter(i=>i.cat !== items[0].cat)
        setBasket([...filteredBasket, ...items])
    }

    //check if specific radio name exists in one of the object in the basket, return bool
    const isRadioNameInBasket =()=>{
        let radioNameInBasket =false;
        
        for(const item of basket){
        
            if(item.radioName)
            {
                if(item.radioName === equipmentType.radioName && item.cat === equipmentType.title)
                {
                    radioNameInBasket = true;
                }
            }
        }
        
        return radioNameInBasket
    }

    function isSelected(){
        if(isRadioNameInBasket()){
            return true;
        }
        
        return false;
    }
    return(
            <div className={`${css.wrapper} ${css.noTrans}`} >
                <div className={`${css.itemBox}  ${visibility ? css.active : ''}`} style={visibility ? { maxHeight: `${100 + (value * 58)}px` } : { maxHeight: '38px' }}>

                    {/* Select qty of devices */}
                    <div>
                        <Select 
                        totalQty={equipmentType.totalQty}  
                        editable={equipmentType.editable} 
                        radioName={equipmentType.radioName} 
                        radioSelected={isSelected()}
                        itemsInBasket={value} 
                        updateQty={updateQty}
                        gridAreaNameSelect="select" 
                        gridAreaNameLabel="label"/>
                    </div>
                    
                    {/* Label for select like 'Door Sensor' */}
                    <div className={css.itemLabel} >
                        <label>{equipmentType.title}</label>
                    </div>

                    {/* 3 dots ... option indication to open list of devices */}
                    {value !== 0 && (
                        <div className={css.itemOption} onClick={toggleVisibility}>
                        {visibility ? <SlOptionsVertical />:<SlOptions/>}
                        </div>
                    )}

                    {/* List of devices */}
                    <div className={`  ${css.slideDown} ${visibility ? css.active : ''}`} style={visibility ? { maxHeight: `${100 + (value * 39.8)}px` } : { maxHeight: '0px' }} >
                        <ItemList 
                        basket={checkWithBasket(equipmentType)} 
                        updateBasketItemsDetails={updateBasketItemsDetails} 
                        setModifiedModel={setModifiedModel} 
                        value={value} 
                        labelEnding={equipmentType.labelEnding} 
                        fixedLocation = {equipmentType.fixedLocation}
                        setView={setView} 
                        setComponentIndex={setComponentIndex} 
                        setTypeIndex={setTypeIndex}/>
                    </div>
                </div> 
            </div>
        
    )
}
export default SelectQty;