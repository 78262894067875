export const formatName=(name='')=>{
  return name.replace(/\b\w/g, char => char.toUpperCase());
}
  
export const validateName=(name)=>{
    // Regular expression for validating city names
    const regex = /^[a-zA-Z\u0080-\u024F\s\-']{2,50}$/;
  
    // Test the city name against the regular expression
    return regex.test(name);
}
  
  