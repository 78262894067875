import React, { useEffect, useState, useRef } from 'react';
import css from './CallList.module.css';
import Recipient from './Recipient/Recipient';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { formatPhone,validatePhone} from '../../../AppCommonComponents/FormInput/Phone';
import {formatName, validateName} from '../../../AppCommonComponents/FormInput/Name'
const CustomerInfo = ({ 
    nextStep, 
    prevStep, 
    customerInfo, 
    callList, 
    setCallList
}) => {
    
    const [isValid, setIsValid] = useState(false);
    const initialRender = useRef(true);

    const generateId = () => `id_${new Date().getTime()}`;

  
    useEffect(() => {
        const allValid = callList.every(recipient => 
            recipient.valid.name && recipient.valid.phone && recipient.valid.passcode
        );
        setIsValid(allValid);
    }, [callList]);

    const addRecipient = () => {
        const newRecipient = {
            id: generateId(),
            name: '',
            phone: '',
            passcode: '',
            isChecked: true,
            valid:{
                name:false,
                phone:false,
                passcode:false,
            }
        };
        setCallList(callList => [
            ...callList,
            newRecipient
        ]);
    };

    const deleteRecipient = (id) => {
        if (callList.length > 1) {
            setCallList(callList => callList.filter(rec => rec.id !== id));
        }
    };
    
   
    useEffect(() => {
            validateData()
        
        
    }, [callList]);

    const validateData = () => {
        setCallList((prevCallList) => {
            return prevCallList.map((recipient) => ({
                ...recipient,
                valid: {
                    name: validateName(recipient.name),
                    phone: validatePhone(recipient.phone),
                    passcode: recipient.passcode !== ''
                }
            }));
        });
    };
    
    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
            if (callList.length === 0 && customerInfo && customerInfo.fname && customerInfo.lname && customerInfo.phone) {
                const newRecipient = {
                    id: generateId(),
                    name: `${customerInfo.fname} ${customerInfo.lname}`,
                    phone: customerInfo.phone,
                    passcode: '',
                    isChecked: true,
                };
                setCallList([newRecipient]);
            }
        }
    }, [customerInfo, callList, setCallList]);

   // const [triggerValidataion, setTriggerValidation] = useState(false)
    
    
    const handleUserInput = (id, e) => {
        let { name, value } = e.target;
        if(name === 'name'){
            value = formatName(value);
        }
        if(name === 'phone'){
            value = formatPhone(value);
        }
        
        setCallList(prevRecipients => 
            prevRecipients.map(recipient =>
                recipient.id === id ? { ...recipient, [name]: value } : recipient
            )
        );
        //setTriggerValidation(!triggerValidataion)
    };

    const handleCheckboxChange = (id) => {
        setCallList(prevRecipients =>
            prevRecipients.map(recipient =>
                recipient.id === id ? { ...recipient, isChecked: !recipient.isChecked } : recipient
            )
        );
    };

    const moveRecipient = (dragIndex, hoverIndex) => {
        const draggedRecipient = callList[dragIndex];
        setCallList(
            update(callList, {
                $splice: [[dragIndex, 1], [hoverIndex, 0, draggedRecipient]],
            }),
        );
    };

    useEffect(() => {
        validateData()
    }, [callList]);
   
    return (
        <DndProvider backend={HTML5Backend}>
            <div className={css.proceedBox}>
                <div className={css.messageBox}>
                    <h2>Call List</h2>
                    <div className={css.descriptionBox}>
                        <div className={css.description}>
                            <p className={css.messageInstruction}>Add a few individuals you wish to be notified in case of an alarm.</p>
                        </div>
                    </div>
                    <div className={css.itemBox}>
                        {callList.map((rec, index) => (
                            <Recipient
                                key={rec.id}
                                id={rec.id}
                                name={rec.name}
                                phone={rec.phone}
                                passcode={rec.passcode}
                                isChecked={rec.isChecked}
                                valid={rec.valid}
                                order={index + 1}
                                deleteRecipient={deleteRecipient}
                                handleUserInput={handleUserInput}
                                handleCheckboxChange={handleCheckboxChange}
                                index={index}
                                moveRecipient={moveRecipient}
                            />
                        ))} 
                    </div>
                    
                    <div className={css.addButtonBox}>
                        <button className={css.addButton} onClick={addRecipient}>Add Another Person</button>
                    </div>
                </div>
                
                <div className={css.buttonBox}>
                    <div className={css.checkboxBox} >
                        <div className={css.checkbox} >
                        </div>
                        <div className={css.term}>
                        <p></p>
                        </div>
                    </div>
                    <button
                        className={css.buttonActive}
                        onClick={prevStep}
                    >
                        Back
                    </button>
                    <button
                        className={isValid ? css.buttonActive : css.buttonInActive}
                        onClick={nextStep}
                        disabled={!isValid}
                    >
                        Next
                    </button>
                </div>
            </div>
        </DndProvider>
    );
};

export default CustomerInfo;
