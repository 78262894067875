import React, { useState, useEffect } from 'react';
import css from './InfoModal.module.css';

const InfoModal = ({ modalData = [], setShowModal }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current index
    const [animation, setAnimation] = useState('slide-in'); // Control slide animation
    const [isImageLoaded, setIsImageLoaded] = useState(false); // Track image load state

    useEffect(() => {
        // Close modal when all data has been displayed
        if (currentIndex >= modalData.length) {
            setShowModal(false)
        }
    }, [currentIndex, modalData.length]);

    const handleNext = () => {
        setAnimation('slide-out'); // Trigger slide-out animation
        setTimeout(() => {
            setCurrentIndex((prevIndex) => prevIndex + 1); // Move to next modal
            setAnimation('slide-in'); // Reset slide-in animation for the new modal
            setIsImageLoaded(false); // Reset image load state for the new modal
        }, 400); // Match this with your CSS transition duration
    };

    const handleImageLoad = () => {
        setIsImageLoaded(true); // Set image as loaded when the load event fires
    };

    const currentModal = modalData[currentIndex];

    if (!currentModal) return null; // If there are no more modals, return null

    return (
        <div className={`${css.modalOverlay} ${css[animation]} show`}>
            <div className={css.modalBox}>
                {/* Image */}
                <div className={css.imageBox}>
                    {/* Set a fixed width and height for the image container to prevent resizing */}
                    <div className={css.imagePlaceholder}>
                        <img
                            src={currentModal.img}
                            alt="modal content"
                            onLoad={handleImageLoad}
                            className={`${isImageLoaded ? css.fadeIn : css.hidden}`}
                            style={{
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    </div>
                </div>
                {/* Dots Indicator */}
                <div className={css.dotsContainer}>
                    {modalData.map((_, index) => (
                        <span 
                            key={index} 
                            className={`${css.dot} ${currentIndex === index ? css.activeDot : ''}`}
                        ></span>
                    ))}
                </div>
                {/* Header and Content */}
                <div className={css.textBox}>
                    <div className={css.headerBox}>
                        <h2>{currentModal.header}</h2>
                    </div>
                    <div className={css.contentBox}>
                        <p>{currentModal.content}</p>
                    </div>
                </div>
                
                {/* Next/Close Button */}
                <div className={css.buttonBox}>
                    <button 
                        className={css.button} 
                        onClick={handleNext}
                    >
                        {currentIndex === modalData.length - 1 ? 'Close' : 'Got it'}
                    </button>
                </div>
                
            </div>
        </div>
    );
};

export default InfoModal;
