import React from 'react';
import css from "./SubmitButton.module.css"
const SubmitButton =(props)=>{
    return(
        <div className={css.box}>
            <button type="submit" {...props}>{props.text}</button>
        </div>
    )
}
export default SubmitButton;

