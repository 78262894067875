import React, {useEffect, useState}from 'react';
import css from './MainView.module.css';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import FeaturesCheckList from './FeaturesChecklist/FeaturesChecklist';
import SectionTitleWithEditButton from '../AppCommonComponents/SectionTitleWithEditButton/SectionTitleWithEditButton';
import PriceDisplay from '../AppCommonComponents/Price/PriceDisplay/PriceDisplay';
import DeviceList from './DeviceList/DeviceList';
import ProductImage from './ProductImage/ProductImage';
import ProductTitle from './ProductTitle/ProductTitle';
import SvgBlueWaveWhiteBackground from '../../assets/svg/backgrounds/svgBlueWaveWhiteBackground';
import SvgBackground from '../../webComponents/Hooks/SvgBackground';
import { GiInfo } from "react-icons/gi";
import InfoModal from '../../webComponents/common/InfoModal/InfoModal';
import navigation_icons from '../../webComponents/common/InfoModal/img/navigation_icons.png'
import cart_page from '../../webComponents/common/InfoModal/img/cart.png'
import edit_page from '../../webComponents/common/InfoModal/img/edit.png'
import plan_page from '../../webComponents/common/InfoModal/img/plan.png'
import email_page from '../../webComponents/common/InfoModal/img/email.png'
import order_page from '../../webComponents/common/InfoModal/img/order.png'
import exit_page from '../../webComponents/common/InfoModal/img/exit.png'
import tip_icon from '../../webComponents/common/InfoModal/img/tip_icon.png'

const MainView = ({
    project, 
    monthly, 
    upfront, 
    view, 
    setView, 
    basket, 
    contractDuration,
    discount,
    tipsDisplayed,
    setTipsDisplayed
}) => {
    const[showModal, setShowModal] = useState(true);
    
    const handleClick = () => {
        setTipsDisplayed((prevtipsDisplayed) => ({
            ...prevtipsDisplayed,
            main: !prevtipsDisplayed.main // Toggle the 'main' flag
            
        }));
        
    };
    useEffect(()=>{
        if(!showModal){
            handleClick();
        }
        
    },[showModal])
  

    const modalData = [
        { header: 'How To Use The App', content: 'Navigate easily using the icons at the bottom of the screen.', img: navigation_icons },

        { header: 'View Your Cart', content: 'Review your selected components, payment plan, and a feature checklist based on your chosen equipment.', img: cart_page },

        { header: 'Edit Your Package', content: 'Personalize your package by adding sensors, cameras, or automation, or upgrade models. View images and explore details for each component.', img: edit_page },

        { header: 'Choose Payment Plan', content: 'Customize your payment plan to suit your needs. Choose a contract, go contract-free, or opt for a contract with a downpayment - your choice.', img: plan_page },

        { header: 'Email Yourself PDF', content: 'Send the estimate to your inbox along with a link to your configuration for further edits.', img: email_page },

        { header: 'Place Your Order', content: 'Ready for installation? Finalize and place your order here.', img: order_page },

        { header: 'Exit to Website', content: 'Return to the main site when you\'re done.', img: exit_page },

        { header: 'Access Quick Guide', content: 'Tap the info icon anytime for quick guidance.', img: tip_icon },
    ];
    
    
    

    useEffect(() => {
        const handleBeforeUnload = (e) => {
          e.preventDefault();
          e.returnValue = ''; // This triggers the dialog in most browsers
        };
      
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

      useEffect(() => {
        // Back button prevention logic
        window.history.pushState(null, document.title, window.location.href);
    
        const handleBackButton = () => {
          window.history.pushState(null, document.title, window.location.href);
        };
    
        window.addEventListener('popstate', handleBackButton);
    
        // Cleanup function to remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('popstate', handleBackButton);
        };
      }, []);



      

    return (
        <div className={css.sectionWrapper} style={SvgBackground(SvgBlueWaveWhiteBackground())}>
            {/* Conditionally render the modal based on showInstructions */}
            {tipsDisplayed.main && (
                <InfoModal 
                    modalData={modalData} 
                    setShowModal={setShowModal}
                    
                />
            )}
            
            <div className={css.body}>
                <div className={css.bodyElementsBox}>
                    <div className={css.titleBox}>
                        <div className={css.title}>
                            <ProductTitle projectDeviceName={project.deviceName} />
                        </div>
                        
                        <div className={css.icon} onClick={handleClick}>
                            <GiInfo />
                        </div>
                    </div>
                    
                    <div className={css.productElementsBox}>
                        <ProductImage projectDeviceImage={project.deviceImage} projectDeviceName={project.deviceName} />
                        <FeaturesCheckList projectCheckList={project.checkList} basket={basket} />
                    </div>
                </div>
                <SectionTitleWithEditButton title={project.selectedComponentsSectionTitle} setView={() => setView('edit')} />
                <DeviceList basket={basket} project={project} />
            </div>

            <div className={css.footer}>
                <SectionTitleWithEditButton title={contractDuration === 1 ? "Contract: Month-to-Month" : `Contract: ${contractDuration}-Month`} setView={() => setView('payment')} />
                <div className={css.priceWrapper}>
                    <PriceDisplay upfront={upfront} monthly={monthly} discount={discount} />
                </div>      
            </div>

            <div className={css.header}>
                <NavigationElementsWrapper>
                    <AppMenu setView={setView} view={view} />
                </NavigationElementsWrapper>
            </div>
        </div>
    );
};

export default MainView;
