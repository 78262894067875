import css from './CustomerInfo.module.css';
import React, { useEffect, useState } from 'react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import {formatAddress, validateAddress} from '../../../AppCommonComponents/FormInput/Address';
import {formatPostal, validatePostal} from '../../../AppCommonComponents/FormInput/Postal';
import {formatName, validateName} from '../../../AppCommonComponents/FormInput/Name';
import {formatPhone, validatePhone} from '../../../AppCommonComponents/FormInput/Phone';
import {formatEmail, validateEmail} from '../../../AppCommonComponents/FormInput/Email';
import { FaCheck } from "react-icons/fa6";
import { FaStarOfLife } from "react-icons/fa6";

const CustomerInfo = ({ 
    nextStep, 
    prevStep, 
    monitoringAddress, 
    customerInfo, 
    setCustomerInfo,
    customerType
 }) => {

    const [isChecked, setIsChecked] = useState(true);
    const [isValid, setIsValid]= useState({
        business:false,
        fname:false,
        lname:false,
        phone:false,
        email:false,
        address:false,
        unit:false,
        city:false,
        province:false,
        postal:false,
        country:false
    })          
    
    const [isFormValid, setIsFormValid]=useState(false)

    const validateBusiness=()=>{
         return customerType==='business'?customerInfo.business!=='':true;
    }
    const validatePostalZip=()=>{
        return isChecked?validatePostal(customerInfo.postal):customerInfo.postal!=='';
    }
    const validateCountry=()=>{
        return !isChecked?validateName(customerInfo.country):true;
    }
    const validateForm=()=>{
        setIsValid({
            business: validateBusiness(),
            fname: validateName(customerInfo.fname),
            lname: validateName(customerInfo.lname),
            phone: validatePhone(customerInfo.phone),
            email: validateEmail(customerInfo.email),
            address: validateAddress(customerInfo.address),
            unit: true,
            city: validateName(customerInfo.city),
            province: customerInfo.province!=='',
            postal: validatePostalZip(),
            country: validateCountry()
        })
        const valid =
        validateBusiness()&&
        validateName(customerInfo.fname)&&
        validateName(customerInfo.lname)&&
        validatePhone(customerInfo.phone)&&
        validateEmail(customerInfo.email)&&
        validateAddress(customerInfo.address)&&
        validateName(customerInfo.city)&&
        customerInfo.province!==''&&
        validatePostalZip()&&
        validateCountry();
        setIsFormValid(valid);
    }

    useEffect(()=>{
        if(isChecked){
            setCustomerInfo(prevState => ({
                ...prevState,
                address: monitoringAddress.address,
                unit: monitoringAddress.unit,
                city: monitoringAddress.city,
                province: monitoringAddress.province,
                postal: monitoringAddress.postal
              }));
        }else{
            setCustomerInfo(prevState => ({
                ...prevState,
                address: '',
                unit: '',
                city: '',
                province: '',
                postal: ''
        }))};
    },[isChecked])

    useEffect(()=>{
        validateForm();
    },[customerInfo])

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    
    const handleCustomerInfoChange = (e) => {
        let { name, value } = e.target;
        if(name === 'address' || name === 'unit'){
            value = formatAddress(value);
        }
        if(name === 'fname' || name === 'lname' || name === 'city'|| name === 'business' || name==='country'){
            value = formatName(value);
        }
        if(name === 'phone'){
            value = formatPhone(value);
        }
        if(name === 'email'){
            value = formatEmail(value);
        }
        if(name === 'postal'){
            if(isChecked){
                value = formatPostal(value);
            }
            
        }
        setCustomerInfo({
            ...customerInfo,
            [name]: value,
        });
        validateForm();
    }
    

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Customer Information</h2>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>Please provide the customer's full information.</p>
                    </div>
                </div>
                <div className={css.itemBox}>
                    <div className={css.itemWrapper}>
                        {customerType === 'business' && (
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='business'>Business Name*</label>
                            <input
                                className={css.inputField}
                                id='business'
                                name='business'
                                type='text'
                                required='required'
                                value={customerInfo.business}
                                autoComplete="organization"
                                onChange={handleCustomerInfoChange}
                            />{isValid.business?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        )} 
                        
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='fname'>First Name*</label>
                            <input
                                className={css.inputField}
                                id='fname'
                                name='fname'
                                type='text'
                                required='required'
                                value={customerInfo.fname}
                                autoComplete="given-name"
                                onChange={handleCustomerInfoChange}
                            />{isValid.fname?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='lname'>Last Name*</label>
                            <input
                                className={css.inputField}
                                id='lname'
                                name='lname'
                                type='text'
                                required='required'
                                value={customerInfo.lname}
                                autoComplete="family-name"
                                onChange={handleCustomerInfoChange}
                            />{isValid.lname?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='phone'>Phone*</label>
                            <input
                                className={css.inputField}
                                id='phone'
                                name='phone'
                                type='text'
                                required='required'
                                value={customerInfo.phone}
                                autoComplete="tel"
                                onChange={handleCustomerInfoChange}
                            />{isValid.phone?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='email'>Email*</label>
                            <input
                                className={css.inputField}
                                id='email'
                                name='email'
                                type='text'
                                required='required'
                                value={customerInfo.email}
                                autoComplete="email"
                                onChange={handleCustomerInfoChange}
                            />{isValid.email?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.checkboxBox} >
                            <div className={css.checkbox} onClick={handleCheckboxChange}>
                                {isChecked?<MdOutlineCheckBox/>:<MdOutlineCheckBoxOutlineBlank/>}
                            </div>
                            <div className={css.term}>
                                <p>Use the same address</p>
                            </div>
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='address'>Address*</label>
                            <input
                                className={css.inputField}
                                id='address'
                                name='address'
                                type='text'
                                required='required'
                                value={customerInfo.address}
                                autoComplete="street-address"
                                onChange={handleCustomerInfoChange}
                            />{isValid.address?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='unit'>Unit</label>
                            <input
                                className={css.inputField}
                                id='unit'
                                name='unit'
                                type='text'
                                value={customerInfo.unit}
                                onChange={handleCustomerInfoChange}
                            />{isValid.unit?<FaCheck className={css.validoff}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='city'>City*</label>
                            <input
                                className={css.inputField}
                                id='city'
                                name='city'
                                type='text'
                                required='required'
                                value={customerInfo.city}
                                autoComplete="address-level2"
                                onChange={handleCustomerInfoChange}
                            />{isValid.city?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        
                        {isChecked?(
                            <>
                            <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='province'>Province*</label>
                            <input
                                className={css.inputField}
                                id='province'
                                name='province'
                                type='text'
                                required='required'
                                value={customerInfo.province}
                                autoComplete="address-level1"
                                onChange={handleCustomerInfoChange}
                            />{isValid.province?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                            </div>
                            <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='postal'>Postal*</label>
                            <input
                                className={css.inputField}
                                id='postal'
                                name='postal'
                                type='text'
                                required='required'
                                value={customerInfo.postal}
                                autoComplete="postal-code"
                                onChange={handleCustomerInfoChange}
                                maxLength={7} // Maximum length to accommodate format 'A1A 1A1'
                            />{isValid.postal?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                            </div>
                        </>
                        ):(
                            <>
                            <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='province'>Province/State/Region*</label>
                            <input
                                className={css.inputField}
                                id='province'
                                name='province'
                                type='text'
                                required='required'
                                value={customerInfo.province}
                                autoComplete="address-level1"
                                onChange={handleCustomerInfoChange}
                            />{isValid.province?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='postal'>Postal/ZIP Code*</label>
                            <input
                                className={css.inputField}
                                id='postal'
                                name='postal'
                                type='text'
                                required='required'
                                value={customerInfo.postal}
                                autoComplete="postal-code"
                                onChange={handleCustomerInfoChange}
                                maxLength={7} // Maximum length to accommodate format 'A1A 1A1'
                            />{isValid.postal?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        <div className={css.inputContainer}>
                            <label className={css.inputLabel} htmlFor='country'>Country*</label>
                            <input
                                className={css.inputField}
                                id='country'
                                name='country'
                                type='text'
                                required='required'
                                value={customerInfo.country}
                                autoComplete="country-name"
                                onChange={handleCustomerInfoChange}
                            />{isValid.country?<FaCheck className={css.valid}/>:<FaStarOfLife className={css.inValid}/>}
                        </div>
                        </>
                        )

                        }
                        
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                <div className={css.checkboxBox}>
                    <div className={css.checkbox} >
                    </div>
                    <div className={css.term}>
                        <label></label>
                    </div>
                </div>
                <button
                    className={css.buttonActive}
                    onClick={prevStep}
                >
                    Back
                </button>
                <button
                    className={isFormValid ? css.buttonActive : css.buttonInActive}
                    onClick={nextStep}
                    disabled={!isFormValid}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default CustomerInfo;
