import css from './OrderView.module.css';
import React, { useState, useEffect} from 'react';
import SvgBackground from '../../webComponents/Hooks/SvgBackground'
import SvgBlueWave from '../../assets/svg/backgrounds/svgBlueWave';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';

import Spinner from './OrderSteps/Spinner/Spinner';
import RotateLoader from 'react-spinners/RotateLoader';
import Initial from './OrderSteps/Initial/Initial';
import PackageConfirmation from './OrderSteps/PackageConfirmation/PackageConfirmation';
import CustomerInfo from './OrderSteps/CustomerInfo/CustomerInfo';
import PlanConfirmation from './OrderSteps/PlanConfirmation/PlanConfirmation';
import CreditConfirmation from './OrderSteps/CreditConfirmation/CreditConfirmation';
import ServicesConfirmation from './OrderSteps/ServicesConfirmation/ServicesConfirmation';
import UpfrontConfirmation from './OrderSteps/UpfrontConfirmation/UpfrontConfirmation';
import MonthlyConfirmation from './OrderSteps/MonthlyConfirmation/MonthlyConfirmation';
import TerminationConfirmation from './OrderSteps/TerminationConfirmation/TerminationConfirmation';
import MonitoringAddress from './OrderSteps/MonitoringAddress/MonitoringAddress';
import CallListMessage from './OrderSteps/CallListMessage/CallListMessage'
import CallList from './OrderSteps/CallList/CallList';
import TermsConfirmation from './OrderSteps/TermsConfirmation/TermsConfirmation';
import FinalStep from './OrderSteps/FinalStep/FinalStep';
import OrderConfirmation from './OrderSteps/OrderConfirmation/OrderConfirmation';
import SubmissionError from './OrderSteps/SubmissionError/SubmissionError';

//email, pdf
import emailjs from 'emailjs-com';
import {pdf} from '@react-pdf/renderer';
import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import PDFDocumentSale from './PDFDocumentSale/PDFDocumentSale';
import PDF_ADI_Order  from './PDF_ADI_Order/PDF_ADI_Order';
import MyDocument from './PDFDocumentSale/DemoPdf';
const OrderView = ({
    valet,
    tax, 
    project, 
    basket, 
    view, 
    setView, 
    contractDuration,
    upfront, 
    monthly, 
    monitoringAddress, 
    setMonitoringAddress, 
    customerInfo, 
    setCustomerInfo, 
    customerType,
    callList,
    setCallList,
    confirmationEmail,
    setConfirmationEmail}) => {

    const [step, setStep]=useState(1)
    const [emailSent, setEmailSent] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(() => {
        if (step === 19 && !emailSent) {
            (async () => {
                const emailSuccess = await sendBothEmails();
                if (emailSuccess) {
                    setEmailSent(true);
                    setTimeout(() => {
                        setStep(21);
                    }, 4000);
                } else {
                    setEmailSent(false);
                    setTimeout(() => {
                        setStep(20);
                    }, 4000);
                }
            })();
        }
    }, [step, emailSent]);
    

    const nextStep=()=>setStep(step+1)
    const prevStep=()=>setStep(step-1)
    const [pdfID, setPdfID] = useState(generateShortId() )

    function generateShortId() {
        return Math.floor(100000 + Math.random() * 900000);
    }
    const sendEmail = async () => {
        const serviceId = 'service_52qj8o7';
        const templateId = 'template_q0oan38';
        const userId = 'Pgvr9-YrZHu7sbMO4';
        const ccEmail = 'tech@securepol.com';
    
        try {
            const blob = await pdf(<PDFDocumentSale  
                pdfID={pdfID}
                monitoringAddress={monitoringAddress} 
                customerInfo={customerInfo} 
                callList={callList}
                basket={basket} 
                project={project} 
                upfront={upfront} 
                monthly={monthly} 
                tax={tax} 
                contractDuration={contractDuration} 
            />).toBlob();
            const base64data = await convertBlobToBase64(blob);
            if (!base64data) {
                console.error('Failed to convert PDF blob to base64.');
                return false;
            }
            const response = await emailjs.send(
                serviceId,
                templateId,
                {
                    pdfID: pdfID,
                    pdfFile: base64data, 
                    ...customerInfo,
                    ccEmail: ccEmail,
                },
                userId
            );
            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Email error:', error);
            return false;
        }
    };
    
    const sendADIEmail = async () => {
        const serviceId = 'service_52qj8o7';
        const templateId = 'template_1gxrhhw';
        const userId = 'Pgvr9-YrZHu7sbMO4';
        const ccEmail = 'tech@securepol.com';
    
        try {
            const blob = await pdf(<PDF_ADI_Order  
                pdfID={pdfID}
                monitoringAddress={monitoringAddress} 
                customerInfo={customerInfo} 
                callList={callList}
                basket={basket} 
                project={project} 
                upfront={upfront} 
                monthly={monthly} 
                tax={tax} 
                contractDuration={contractDuration} 
            />).toBlob();
            const base64data = await convertBlobToBase64(blob);
            if (!base64data) {
                console.error('Failed to convert PDF blob to base64.');
                return false;
            }
            const response = await emailjs.send(
                serviceId,
                templateId,
                {
                    pdfID: pdfID,
                    pdfFile: base64data, 
                    ...customerInfo,
                    ccEmail: ccEmail,
                },
                userId
            );
            if (response.status === 200) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Email error:', error);
            return false;
        }
    };
    
      
    
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => resolve(reader.result.split('base64,')[1]);
          reader.onerror = (error) => reject(error);
        });
      };
      const sendBothEmails = async () => {
        const [firstEmailSuccess, secondEmailSuccess] = await Promise.all([
            sendEmail(),
            sendADIEmail()
        ]);
        return firstEmailSuccess && secondEmailSuccess;
    };

    const renderSteep = () =>{
        switch(step){
            case 1:
                return(
                    <Initial
                        step={step}
                        setStep={setStep}
                        nextStep={nextStep}
                        prevStep={prevStep}
                    />
                );
            case 2:
                return(
                    <Spinner 
                        step={step} 
                        setStep={setStep} 
                        message={'Analyzing your package...'} 
                        spinnerTime={4000}
                    />
                        
                );
            case 3:
            return(
                <PackageConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    basket={basket}
                    project={project}
                />
            );
            case 4:
            return(
                <ServicesConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    basket={basket}
                    project={project}
                />
            );
            case 5:
                return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Analyzing payment plan...'} 
                    spinnerTime={4000}
                />
                        
                );
                case 6:
                return(
                <PlanConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                />
                );
            case 7:
                return(
                <CreditConfirmation
                step={step}
                setStep={setStep}
                nextStep={nextStep}
                prevStep={prevStep}
                contractDuration={contractDuration}
                upfront={upfront}
                monthly={monthly}
                />
                );
            case 8:
                return(
                <UpfrontConfirmation
                step={step}
                setStep={setStep}
                nextStep={nextStep}
                prevStep={prevStep}
                contractDuration={contractDuration}
                upfront={upfront}
                monthly={monthly}
                />
                );  
            case 9:
                return(
                <MonthlyConfirmation
                step={step}
                setStep={setStep}
                nextStep={nextStep}
                prevStep={prevStep}
                contractDuration={contractDuration}
                upfront={upfront}
                monthly={monthly}
                />
                );   
            case 10:
                return(
                <TerminationConfirmation
                step={step}
                setStep={setStep}
                nextStep={nextStep}
                prevStep={prevStep}
                contractDuration={contractDuration}
                upfront={upfront}
                monthly={monthly}
                />
                );       
            case 11:
            return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Preparing forms...'} 
                    spinnerTime={4000}
                />
                    
            );
            case 12:
                return(
                <MonitoringAddress
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                />
                );
            case 13:
                return(
                <CustomerInfo
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                />
                );
            case 14:
            return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Preparing call list...'} 
                    spinnerTime={4000}
                />
                    
            );
            case 15:
                return(
                <CallListMessage
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    setCallList={setCallList}
                />
            );
            case 16:
                return(
                <CallList
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                />
            );
            case 17:
                return(
                <Spinner 
                    step={step} 
                    setStep={setStep} 
                    message={'Finalizing application...'} 
                    spinnerTime={6000}
                />   
            );
            case 18:
                return(
                <TermsConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                    confirmationEmail={confirmationEmail}
                    setConfirmationEmail={setConfirmationEmail}
                />
            );
            case 19:
                    return(
                        <>
                        <div className={css.procceedBox}>
                          <div className={css.messageBox}>
                            <div className={css.spinnerContainer}>
                              <RotateLoader  size={12} color="#274E65"/>
                                <div className={css.message}>
                                  <p>Submiting applicaiton...</p>
                                </div>
                            </div>
                          </div>
                        </div>
                        <div className={css.buttonBox}>
                        </div>
                      </>  
                    );
                
            case 20:
                return(
                <SubmissionError
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                    confirmationEmail={confirmationEmail}
                    setConfirmationEmail={setConfirmationEmail}
                />
                );
            case 21:
                return(
                <OrderConfirmation
                    step={step}
                    setStep={setStep}
                    nextStep={nextStep}
                    prevStep={prevStep}
                    contractDuration={contractDuration}
                    upfront={upfront}
                    monthly={monthly}
                    monitoringAddress={monitoringAddress}
                    setMonitoringAddress={setMonitoringAddress}
                    customerInfo={customerInfo}
                    setCustomerInfo={setCustomerInfo}
                    customerType={customerType}
                    callList={callList}
                    setCallList={setCallList}
                    confirmationEmail={confirmationEmail}
                    setConfirmationEmail={setConfirmationEmail}
                />
            );
        }
    }
    return( 
        <>
        {(step < 11) || (step >= 20)?(
        <div className={css.sectionWrapper} style={SvgBackground(SvgBlueWave())}>
            <div className={css.header}>
                <NavigationElementsWrapper>
                    <AppMenu 
                    setView={setView} 
                    view={view}/>
                </NavigationElementsWrapper>
            </div>

            <div className={css.body}>
                {renderSteep()}
            </div>
        </div>
        ):(
        <div className={css.sectionWrapperFull} style={SvgBackground(SvgBlueWave())}>
            

            <div className={css.body}>
                {renderSteep()}
            </div>
        </div>
        )}
       
       </>
    )
}
export default OrderView;

  // <PDFViewer style={{ height: '100vh', width: '100%' }}>
                    //     <PDFDocumentSale 
                    //         monitoringAddress={monitoringAddress} 
                    //         customerInfo={customerInfo} 
                    //         callList={callList}
                    //         basket={basket} 
                    //         project={project} 
                    //         upfront={upfront} 
                    //         monthly={monthly} 
                    //         tax={tax} 
                    //         contractDuration={contractDuration} 
                            
                    //     />
                    // </PDFViewer>
                