
import oh1 from './img/oh1.jpg';
import oh2 from './img/oh2.jpg';
import Cr123aImage from '../../../Batteries/Cr123a.jpg';
import { getPrice } from '../../../../../../Prices';
const PROSIXCTOHR = () => {
    const sensor = {
        title: 'Residential Overhead Garage Door Sensor',
        model: 'PROSIXCTOHR',
        sku: ['PROSIXCTV'],
        group: 'sensor',
        modelViewImage: oh2,
        image: [
            oh2,
            oh1,
            
            Cr123aImage,
        ],
        shortDescription: 'Secures your home overhead garage door.',
        price: getPrice('PROSIXCTOHR'),
        checkListKeyword: ['Burglar'],
        infoHeader: "Overhead Garage Door Sensor.",
        spec: [
            { row1: 'Model:', row2: 'PROSIXCTOHR' },
            { row1: 'Brand:', row2: 'Honeywell' },
            { row1: 'Connectivity:', row2: 'Wired + Wireless' },
            { row1: 'Color:', row2: 'White' },
            { row1: 'Mounting:', row2: 'Surface mount' },
            { row1: 'Power Supply:', row2: 'Battery Powered' },
            { row1: 'Battery Type:', row2: 'Lithium' },
            { row1: 'Wireless Range:', row2: '300+ Feet (Open Air) from main controller' },
            { row1: 'Battery Size:', row2: 'Panasonic CR123A, Duracell DL123A, Huiderui CR123A, 3V' },
            { row1: 'Number of Batteries:', row2: '1' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Product Length:', row2: '3.1 in, 78.7 mm' },
            { row1: 'Product Width:', row2: '1.6 in, 40.6 mm' },
            { row1: 'Product Height:', row2: '1.4 in, 27.9 mm' },
        ],
        info: [
            {
                header: 'How does it work?',
                text: 'The PROSIXCTOHR is designed to secure your overhead garage door with a large wired contact sensor, with the PROSIXCT functioning as a wireless transmitter. Here’s how it operates:',
                section: [
                    { title: "Components" },
                    { text: "The system includes a large wired contact sensor installed on the overhead garage door and the PROSIXCT sensor, which acts as a wireless transmitter to send signals to your security system." },

                    { title: "Magnetic Field & Contact" },
                    { text: "When the overhead garage door is closed, the wired contact sensor detects its position. The PROSIXCT transmits this status wirelessly to the security system as a 'closed' state." },

                    { title: "Opening Detection" },
                    { text: "When the door opens, the wired contact sensor detects the change, and the PROSIXCT immediately transmits this information to the control panel, signaling an opening event." },

                    { title: "Wireless Transmission" },
                    { text: "The PROSIXCT functions as a bridge between the wired contact sensor and the security system, ensuring reliable wireless communication even in large or complex installations." },

                    { title: "Alert or Alarm" },
                    { text: "The control panel can trigger an alarm, send notifications to Securepol's CMS, or alert you via your mobile device based on the system’s settings." },

                    { title: "Battery-Powered Operation" },
                    { text: "The PROSIXCT is powered by a battery, ensuring continuous operation even during power outages. Regular battery checks and replacements are recommended to maintain optimal performance." },
                ],
            },
           
        ],
    };
    return sensor;
};
export default PROSIXCTOHR;
