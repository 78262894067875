import React from 'react';
import css from './PaymentView.module.css';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import { useEffect, useState} from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import { MdOutlineRadioButtonUnchecked } from "react-icons/md";
import { BsCalendar3 } from "react-icons/bs";
import { LuPackageOpen } from "react-icons/lu";
import SvgBackground from '../../webComponents/Hooks/SvgBackground'
import SvgBlueWave from '../../assets/svg/backgrounds/svgBlueWave';
import { GiInfo } from "react-icons/gi";
import InfoModal from '../../webComponents/common/InfoModal/InfoModal';
import customer_type from '../../webComponents/common/InfoModal/img/customer_type.png'
import set_contract from '../../webComponents/common/InfoModal/img/set_contract.png'
import set_upfront from '../../webComponents/common/InfoModal/img/set_upfront.png'
import see_price from '../../webComponents/common/InfoModal/img/see_price.png'
const PaymentView =({
    hardwareTotal, 
    contractDuration, 
    tax, 
    setContractDuration, 
    contractDurationMemo,
    setContractDurationMemo,
    sliderUpfront,setSliderUpfront, 
    inputUpfront, 
    setInputUpfront, 
    customerType, 
    setCustomerType, 
    monthlyTotal, 
    view, 
    setView,
    tipsDisplayed,
    setTipsDisplayed
})=>{
    
    const [upfrontdurationSliderOff, setUpfrontdurationSliderOff] = useState(false)
    const [durationSliderOff, setDurationSliderOff] = useState(false)
    const [inputPlaceHolder, setInputPlaceHolder] = useState(0)
    const [upfrontSliderMoved, setUpfrontSliderMoved]=useState(false);
    const[showModal, setShowModal] = useState(true);

    const handleClick = () => {
        setTipsDisplayed((prevtipsDisplayed) => ({
            ...prevtipsDisplayed,
            plan: !prevtipsDisplayed.plan // Toggle the 'main' flag
        }));
    };
    useEffect(()=>{
        if(!showModal){
            
            handleClick();
        }
    },[showModal])

    const handleTypeChange = (customerType) => {
        setCustomerType(customerType);
    };

    const modalData = [
        { header: 'Select Customer Type', content: 'Choose your customer type to see available sale options.', img: customer_type },
    
        { header: 'Choose Contract Duration', content: 'Adjust the slider to set your contract length, from no contract to up to 60 months.', img: set_contract },
    
        { header: 'Decide on Upfront Payment', content: 'Slide to choose your upfront payment—pay $0 down or more, you’re in control.', img: set_upfront },
    
        { header: 'View Payment Details', content: 'As you adjust the slider, your payment plan updates instantly. What you see is what you pay, with no hidden fees. Installation is included.', img: see_price },
    ];
    
    //Blocks slider on tenant or business, release on homeowner
    useEffect(()=>{
        if(customerType !== 'homeowner')
        {
            setDurationSliderOff(true)
            setUpfrontdurationSliderOff(true);
            setContractDuration(1)
            setSliderUpfront(hardwareTotal)
            setInputUpfront(hardwareTotal)
        }
        else
        {
            setDurationSliderOff(false);
            setUpfrontdurationSliderOff(false);
        }
        
    },[customerType])


    useEffect(()=>{
        //when contract slider is moved to begining (no contract position) it aslo must move upfront slider to max since no contract maens must pay upfornt
        if(contractDuration === 1)
        {
            setSliderUpfront(hardwareTotal)
            setInputUpfront(hardwareTotal)
            setInputPlaceHolder(hardwareTotal)
        }
        //contract slider moved form 1 up
        if(contractDuration>=2){
            //if upfront was set to maximum change it to 0
            //this way when user selects contract slider it resets the upfront payment to indicate $0 down sytuation
            if(sliderUpfront===hardwareTotal)
            {
                setInputUpfront(0)
                setSliderUpfront(0)
            }else{
                //in case when upfront was already manipulated and contract slider is moved it keeps upfrotn slider with its curront value
                setInputUpfront(sliderUpfront)
                setSliderUpfront(sliderUpfront);
            }
            //when user focus on upfornt input it clears and shows placeholder 0
            setInputPlaceHolder(0)
        }
        //fires when contract slider is moved
    },[contractDuration])

    //when upfront slider moved
    useEffect(()=>{
        // if upfront slider was in its max postion and is the upfront slider that was las moved, move contract slider to begining
        
        if(sliderUpfront=== hardwareTotal && upfrontSliderMoved){
            
            setContractDuration(1) 
        }

        //if upfront slider wsa not in max position and upfrotn slider was moved last, set contract sldier to its last seved postion(value)
        if(sliderUpfront !== hardwareTotal && upfrontSliderMoved){
            setContractDuration(contractDurationMemo) 
        }
    },[sliderUpfront])


    //when contract slider is moved read new value and setState
    //mark that upfront slider is not anymore the one that was last used
    const handleContractSliderChange=(e)=>{
        setContractDuration(parseInt(e.target.value))
        setUpfrontSliderMoved(false)
     }

     //when upfront slider was moved, read value, setState, setInput, and mark that upfront slider was used last.
     
    const handleUpfrontSliderChange = (event) => {
        const value = parseInt(event.target.value);
        
        setSliderUpfront(value);
        setInputUpfront(value);
        setUpfrontSliderMoved(true)
        //Remember last positon of contract slider if it was not at the begining
        if(contractDuration !==1){
            setContractDurationMemo(contractDuration)
        }

        
       
     };

    // when user enter numeber to inputbox setState but no higher then hardwareTotal
    const handleInputChange = (event) => {
        let value = parseInt(event.target.value);
        if (/^\d*$/.test(value)) 
        {
            
            if (value>hardwareTotal) {
                setInputUpfront(hardwareTotal);
                setSliderUpfront(hardwareTotal);
            }
            else{
                setInputUpfront(value);
                if(value ===''){
                    setSliderUpfront(0);
                }
                else{
                    setSliderUpfront(parseInt(value));
                }
            }
        }
       
        setUpfrontSliderMoved(true)
    };

    //On focus clear the value of inputbox
    //////Canceled
    const handleInputFocus = () => {
        if(!durationSliderOff){
            setInputUpfront('') 
            if(contractDuration !==1){
                setContractDurationMemo(contractDuration)
            }
            
        };
    }

    //marks for contract slider
  const contractMarks = [
    { value: 1, label: '0' },
    { value: 12, label: '1' },
    { value: 24, label: '2' },
    { value: 36, label: '3' },
    { value: 48, label: '4' },
    { value: 60, label: '5 yr.' },
  ];

  //marks for upfront slider
  const sliderUpfrontMarks = [
    { value: 0, label: '$0' },
    { value: hardwareTotal, label: `$${hardwareTotal.toFixed(2)}` },
  ];



    return(
        <div className={css.sectionWrapper} style={SvgBackground(SvgBlueWave())}>
            {/* Conditionally render the modal based on showInstructions */}
            {tipsDisplayed.plan && (
                <InfoModal 
                    modalData={modalData} 
                    setShowModal={setShowModal}
                    
                />
            )}
           
            
            <div className={css.header}>
                <NavigationElementsWrapper>
                    <AppMenu 
                    setView={setView} 
                    view={view}/>
                </NavigationElementsWrapper>
            </div>
            
            <div className={css.body}>
                <div className={css.pageWrapper}>
                    <div className={css.pagetitle} >
                        Payment Plan
                    </div>
                    
                    <div className={css.infoIcon} onClick={handleClick}>
                        <GiInfo/>
                    </div>
                </div>
                <div className={css.gridContainer}>
                    <div className={css.termsBox}>
                        <div className={css.termsWrapper}>
                            <p></p>
                            <table>{/*
                                <tbody>
                                    <tr>
                                        <td>Sale Type</td>
                                        <td>5 Year Contract</td>
                                    </tr>
                                    <tr>
                                        <td>Upon Installation Cost:</td>
                                        <td>$230</td>
                                    </tr>
                                    <tr>
                                        <td>Nunmber of payment:</td>
                                        <td>60</td>
                                    </tr>
                                    <tr>
                                        <td>Early Termination fee:</td>
                                        <td>100% of remaining balance</td>
                                    </tr></tbody>*/}
                            </table>
                        </div>
                    </div>
                    <div className={css.priceBox}>
                        <div className={css.priceWrapper}>
                            <div className={css.unitBox}>

                                <div className={css.titleBox}>
                                    <div className={css.icon} >
                                        <LuPackageOpen />
                                    </div>
                                    <div className={css.titla}>
                                        <p> Upfront Payment</p>
                                    </div>
                                    
                                </div>
                                <div className={css.priceBody}>
                                    <div className={css.priceFlex}>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>Subtotal</div>
                                            <div className={css.price}>${sliderUpfront.toFixed(2)}</div>
                                        </div>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>5% GST</div>
                                            <div className={css.price}>${(sliderUpfront*tax).toFixed(2)}</div>
                                        </div>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>Total</div>
                                            <div className={css.price}>${((sliderUpfront*tax)+sliderUpfront).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={css.unitBox}>
                                <div className={css.titleBox}>
                                    <div className={css.icon} >
                                        <BsCalendar3 />
                                    </div>
                                    <div className={css.titla}>
                                        <p>Monthly Payment</p>
                                    </div>
                                    
                                </div>
                                <div className={css.priceBody}>
                                <div className={css.priceFlex}>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>Subtotal</div>
                                            <div className={css.price}>${monthlyTotal.toFixed(2)}</div>
                                        </div>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>5% GST</div>
                                            <div className={css.price}>${(monthlyTotal*tax).toFixed(2)}</div>
                                        </div>
                                        <div className={css.subBox}>
                                            <div className={css.priceLabel}>Total</div>
                                            <div className={css.price}>${((monthlyTotal*tax)+monthlyTotal).toFixed(2)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                     <div className={css.settingsBox}  > 
                        <div className={css.typeBox}>
                        <label className={css.typeLabel}>Customer Type</label>
                            <div className={css.typeWrapper}>
                                
                                <div className={css.type} onClick={(e)=>handleTypeChange('homeowner')}>
                                    <div className={css.typeCheckBox}>
                                        {customerType ==='homeowner'?<MdOutlineRadioButtonChecked />:<MdOutlineRadioButtonUnchecked />
}
                                    </div>
                                    <div className={css.checkBoxLabel}>
                                        Homeowner
                                    </div>
                                </div>
                                

                                <div className={css.type} onClick={(e)=>handleTypeChange('tenant')}>
                                    <div className={css.typeCheckBox}>
                                        {customerType ==='tenant'?<MdOutlineRadioButtonChecked />:<MdOutlineRadioButtonUnchecked />
}
                                    </div>
                                    <div className={css.checkBoxLabel}>
                                           Tenant
                                    </div>
                                </div>
                                    
                                <div className={css.type} onClick={(e)=>handleTypeChange('business')}>
                                    <div className={css.typeCheckBox}>
                                     {customerType ==='business'?<MdOutlineRadioButtonChecked />:<MdOutlineRadioButtonUnchecked />
}
                                    </div>
                                    <div className={css.checkBoxLabel}>
                                        Business
                                    </div>
                                </div>
                            </div>
                        </div>  
                        <div className={css.settingsUnitBox}>
                            <div className={css.sliderTitle}>
                                <div>
                                <Typography id="slider-with-marks" gutterBottom>
                                <label className={css.sliderLabel}>{contractDuration === 1?"Contract: Month-to-Month":"Contract: "+contractDuration+"-Month "}</label>
                                </Typography>
                                <Slider
                                    value={contractDuration}
                                    onChange={(e)=>handleContractSliderChange(e)}
                                    aria-labelledby="slider-with-marks"
                                    marks={contractMarks}
                                    step={1}
                                    min={1}
                                    max={60}
                                    height="10"
                                    disabled={durationSliderOff}
                                    className={customerType === 'homeowner' ? css.homeowner : css.notHomeowner}
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            backgroundColor: 'var(--thumb-background-color)',
                                            border: `5px solid var(--thumb-border-color)`,
                                        },
                                        '& .MuiSlider-markLabel': {
                                            fontSize: 'var( --small-label-font-size)',
                                            color: 'var(--mark-label-color)',
                                        },
                                        '& .MuiSlider-track': {
                                            height: '2px',
                                            color: 'var(--track-color)',
                                        },
                                        '& .MuiSlider-rail': {
                                            height: '4px',
                                            background: 'var(--rail-background)',
                                        },
                                    }}
                                />
                                </div>
                            </div>
                        </div>
                        <div className={css.settingsUnitBox}>
                            <div className={css.sliderTitle}>
                                <div>
                                <Typography id="slider-with-marks" gutterBottom>
                                    <>
                                        <label className={css.sliderLabel}>Upfront Payment $</label>
                                        <input 
                                            type="number" 
                                            className={css.upfrontInput} 
                                            placeholder={inputPlaceHolder} 
                                            value={inputUpfront} 
                                            onFocus={handleInputFocus}  
                                            onChange={(e)=>handleInputChange(e)} 
                                            pattern="[0-9]*"
                                            readOnly={upfrontdurationSliderOff?"readOnly":''}
                                        ></input>
                                   </>
                                </Typography>
                                <Slider
                                    value={sliderUpfront}
                                    onChange={(e)=>handleUpfrontSliderChange(e)}
                                    aria-labelledby="slider-with-marks"
                                    marks={sliderUpfrontMarks}
                                    step={0.01}
                                    min={0}
                                    max={hardwareTotal}
                                    disabled={upfrontdurationSliderOff}
                                    className={customerType === 'homeowner' ? css.homeowner : css.notHomeowner}
                                    sx={{
                                        '& .MuiSlider-thumb': {
                                            backgroundColor: 'var(--thumb-background-color)',
                                            border: `5px solid var(--thumb-border-color)`,
                                        },
                                        '& .MuiSlider-markLabel': {
                                            fontSize: 'var( --small-label-font-size)',
                                            color: 'var(--mark-label-color)',
                                        },
                                        '& .MuiSlider-track': {
                                            height: '2px',
                                            color: 'var(--track-color)',
                                        },
                                        '& .MuiSlider-rail': {
                                            height: '4px',
                                            background: 'var(--rail-background)',
                                        },
                                    }}
                                   
                                />
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default PaymentView;