import React from 'react';
import { Link } from 'react-router-dom';
import css from "./BlueLink.module.css";
import { GoChevronRight } from "react-icons/go";

export default function BlueLink({links=[], settings, fontSize}){

    return(
        <div>
            {links && (
                <div className={css.linksWrapper}>
                    {links.map((link,i)=>(
                        <Link key={i} className={`${css.linkto} ${settings.darkBackground ? css.brighter:''}`} to={link.to}>
                            <div className={css.link } >
                               
                                    {typeof link.label === 'string' ? 
                                        <span dangerouslySetInnerHTML={{ __html: link.label }}></span> :
                                        link.to
                                    }                                
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}