import css from './SubmissionError.module.css';
import React, { useEffect, useState } from 'react';
import { MdOutlineCheckBox, MdOutlineCheckBoxOutlineBlank } from "react-icons/md";
import {formatEmail, validateEmail} from '../../../AppCommonComponents/FormInput/Email';

const SubmissionError = ({ 
    setStep,
 }) => {

   

    return (
        <div className={css.proceedBox}>
            <div className={css.messageBox}>
                <h2>Submission Failed!</h2>
                <div className={css.descriptionBox}>
                    <div className={css.description}>
                        <p className={css.messageInstruction}>Please ensure your device is connected to the internet or refresh your internet connection and try again. If the problem persists, contact support for assistance at <br/>(403) 703-1273, ext 2.</p>
                    </div>
                </div>
            </div>
            <div className={css.buttonBox}>
                    <div className={css.checkboxBox}>
                        <div className={css.checkbox}>
                           
                        </div>
                        <div className={css.term}>
                            <label></label>
                        </div>
                    </div>
                    
                    <button
                        className={css.buttonActive}
                        onClick={()=>setStep(11)}
                    >
                        Submit
                    </button>
                </div>
        </div>
    );
};

export default SubmissionError;
