import to1 from '../img/to1.jpg';
import to2 from '../img/to2.jpg';
import to3 from '../img/to3.jpg';
import to4 from '../img/to4.jpg';
import to5 from '../img/to5.jpg';
import to6 from '../img/to6.jpg';
import to7 from '../img/to7.jpg';
import to8 from '../img/to8.jpg';
import to9 from '../img/to9.jpg';
import to10 from '../img/to10.jpg';
import to11 from '../img/to11.jpg';
import to12 from '../img/to12.jpg';
import to13 from '../img/to13.jpg';
import to14 from '../img/to14.jpg';
import to15 from '../img/to15.jpg';
import to16 from '../img/to16.jpg';
import to17 from '../img/to17.jpg';
import to18 from '../img/to18.jpg';
import to19 from '../img/to19.jpg';
import to20 from '../img/to20.jpg';
import to21 from '../img/to21.jpg';


const Existing=()=>{
    const sensor = {
        title: 'Existing Wireless CO Detector',
        model:'Existing Wireless Sensor',
        sku:[],
        group:'takeover',
        modelViewImage:to1,
        image:[to3,to19,to20,to21,to15,to16,to18,to17,to2,to10,to8,to9,to14,to11,to4,to5,to7,to6,to13,to12],
        shortDescription: "Select this option to integrate existing wireless sensors from a previous alarm system with the ProSeries Touchscreen panel. A Takeover Module will be added to the panel configuration. Click on 'Learn More' to ensure sensor compatibility.",




        price: 0,
        checkListKeyword:['CO'],
        infoHeader: "If You Have One of the Panels Above, You Can Integrate Their Wireless Sensors with the ProSeries Panel",


        spec:[],
        info:[{
            header: 'How Does It Work?',
            text: 'By selecting this option, the ProSeries panel will come equipped with the built-in PROTAKEOVER module, allowing your existing wireless sensors to connect seamlessly to the new ProSeries Touchscreen panel. The PROTAKEOVER module is designed to repurpose your current wireless sensors, enabling them to communicate with the upgraded panel. This lets you modernize your alarm system without the need to replace all of your sensors. It’s important to note that this process doesn’t involve installing new sensors, repairing them, or replacing batteries. Instead, it utilizes the wireless sensors you already have. You can also combine old wireless or wired sensors with new ones for enhanced flexibility and coverage. With the PROTAKEOVER module, your existing sensors will continue to function smoothly with the updated ProSeries panel, providing a modern solution without sensor replacement. If your current alarm panel isn’t displayed in the images above, please opt for ProSeries sensors instead of this option.',
        
            section: [
                { title: "" },
                { text: "" },]
        }
        ]
    }
    return sensor
}
export default Existing;