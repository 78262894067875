import React, { useState, useEffect } from 'react';
import {Page, Text, View, Document, StyleSheet, Image, Link, Font} from '@react-pdf/renderer';
import SecurepolLogo from './Images/SecurepolLogo';
import WaterMark from './Images/watermark'
import PDFbackgroundline from './Images/PDFbackgroundline'
import { FaCheck } from "react-icons/fa";

const PDFDocument = ({ 
  pdfID,
  basketID, 
  basket, 
  project, 
  upfront, 
  monthly,
  tax, 
  contractDuration, 
  monitoringAddress, 
  customerInfo,
  
}) => {
  
  
function displayFormattedDate() {
  // Get the current date
  const now = new Date();

  // Extract components of the date
  const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(now.getDate()).padStart(2, '0');
  const year = now.getFullYear();

  // Format the date
  const formattedDate = `Date: ${month}/${day}/${year}`;

  return formattedDate;
}

  // useEffect(() => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(function(position) {
  //       setLatitude(position.coords.latitude);
  //       setLongitude(position.coords.longitude);
  //     });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }, []); // Empty dependency array to run the effect only once

  // Register the font
Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxP.ttf',
});
  const styles = StyleSheet.create({
    page:{
      paddingTop: 20,
      paddingBottom: 20,
    },

    contentWrapper:{
      width:'100%',
      display: 'flex',
      flexDirection: 'column',
    },

    headerWrapper:{
      margin:10,
      padding:10,
      margingTop:0,
      paddingTop:0,
      fontSize:24,
      display: 'flex',
      flexDirection: 'row',
    },
    companyAddressBox:{
      flex:1,
      fontSize:10,
      color: '#274E65',
    },
    image: {
      width:180,
      marginLeft:-6,
      marginBottom:1,
    },
    imageb: {
      position:'absolute',
    },
    addressHeader:{
      fontSize:14,
    },
    addressBody:{
      fontSize:8,
    },
    pdfTitleBox:{
      fontSize:32,
      margin:10,
      color: '#274E65',
      textAlign:'center',
      display: 'flex',
      flexDirection: 'column',
    },
    pdfNumber:{
      fontSize:10,
      textAlign:'right',
    },
    pdfDate:{
      fontSize:10,
      textAlign:'right',
    },
    pdfloc:{
      fontSize:5
    },
    addressWrapper:{
      display: 'flex',
      flexDirection: 'row',
    },
    link: {
      color: '#274E65',
      textDecoration: 'none',
    },
    customerAddressWrapper:{
      padding:20,
      flex:1,
      display: 'flex',
      flexDirection: 'column',
      fontSize:12,
    },
    companyAddressWrapper:{
      padding:20,
      flex:1,
      display: 'flex',
      flexDirection: 'column',
      fontSize:12,
    },
    headerlabel:{
      fontSize:18,
      color:'#274E65',
      fontWeight: 'bold',
      marginBottom: 10,
      borderBottomWidth: 1,
      borderBottomColor: '#333',
    },
    addressBox:{
      display: 'flex',
      flexDirection: 'row',
      fontSize:10,
      color:'#333',
      marginLeft:5,
    },
    address:{
      flexShrink:1,
    },


    itemsListWrapper:{
      padding:10,
      margin:10,
      
    },
    itemsListBody:{
    },
    itemDetails:{
        fontSize:8,
        marginLeft:40,
        color:'#333'
    },
   





    pdfTitle:{
      
      borderColor: '#000',
    },
    
    section: {
      width:'100%',
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft:26,
      marginRight:0,
      marginTop: 10,
      marginBottom: 0,
    },
    tableRow: {
      
      color:'#274E65',
      marginTop:1,
      flexDirection: 'row',
      alignItems: 'center',
    },
    tableHeader:{
      fontSize:8,
      margin: 4,
    },
    tableCell: {
      margin: 4,
      fontSize: 12,
      flexGrow: 0,
      color:'#333'
    },
    servicesBox:{
      fontSize: 10,
      margin: 5,
    },



    itemDetailsBox:{
      display:'flex',
      flexDirection:'column',
      fontSize: 9,
      marginLeft:40,
      marginRight:40,
      
      color:'#333'
    },
    itemDetailsHeaderBox:{
      display:'flex',
      flexDirection:'row',
      backgroundColor:'#f7f8f9',
    },  
      itemDetailsHeaderLocatoin:{
        flex:1,
      },
      itemDetailsHeaderModel:{
        flex:1,
      },


      textMarg:{
        margin:0.5,
      },
    itemDetailsWrapper:{
    },

    itemDetailsRow:{
      display:'flex',
      flexDirection:'row',
    },
    itemDetailsOrderLabel:{
      flex:1
    },
    itemDetailsModelLabel:{
      flex:1,
    },

    paymentPlanBox:{
      marginTop:40,
    },
    paymentPlanLabel:{
      fontSize:16,
      color: '#274E65',
    },

    paymentDetailBox:{
      
      color:'#000',
      marginLeft:0,
      fontSize:10,
    },


    paymentBoxWrapper:{
      display:'flex',
      flexDirection:'row',
    },
    paymentTermsBox:{
      margin:16,
      flex:1,
    },
    termsBox:{
      margin:4,
      display:'flex',
      flexDirection:'column'
    },
    termRow:{
      margin:1,
      display:'flex',
      flexDirection:'row',
    },
    termLabel:{
      fontSize:8,
      color:'#000',
    },
    termDescriptionFixed:{
      fontSize:8,
      color:'#777',
    },
    termDescription:{
      fontSize:8,
      color:'#777',
    },
    paymentPriceBox:{
    flex:1,
    },

    contractSaleTerm:{
      fontSize:8,
      color:'#777',
    },



    
      priceWrapper:{
        margin:16,
      },

      
        priceLabel:{
          color: '#274E65',
          borderBottomWidth: 1,
          borderBottomColor: '#eee',
          fontSize:10,
        },
        priceLabelText:{
          padding:2,
          fontSize:14,
        },
        priceBox:{
          padding:2,
          display:'flex',
          flexDirection:'row'
        },
          priceName:{
            flex:1,
            fontSize:10,
            color:'grey',
            textAlign:'right',
          },
          priceAmount:{
            flex:1,
            fontSize:10,
            textAlign:'right',
          },

          footerBox:{
            margin:20,
          },
          footerParagraph:{
            marginTop:20,
            fontSize:10,
          },
          footerParagphBox:{
            marginLeft:20,
            marginRight:20,
          },
          pageNumber: {
            position: 'absolute',
            fontSize: 10,
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'grey',
          },
          loc:{
            color:'#eee'
          },
          callListDetailsBox:{
            display:'flex',
            flexDirection:'column',
            fontSize: 10,
            marginLeft:40,
            marginRight:40,
          },
          callListOrder:{
            width:10,
            margin: 4,
            fontSize: 12,
            flexGrow: 0,
            color:'#333',
          },
          callListName:{
            width:200,
            margin: 4,
            fontSize: 12,
            flexGrow: 0,
            color:'#333',
          },
          callListPasscode:{
            width:100,
            margin: 4,
            fontSize: 12,
            flexGrow: 0,
            color:'#333',
          },
          callListAdmin:{
            width:100,
            margin: 4,
            fontSize: 12,
            flexGrow: 0,
            color:'#333',
          },
          callListElement:{
            width:120,
            
          },
          callListRow:{
            flex:1
          },
          callListPhone: {
            width:100,
            margin: 4,
            fontSize: 12,
            flexGrow: 0,
            color:'#333',
          },

          headerOrder:{
            width:10,
            margin: 4,
            fontSize: 8,
            flexGrow: 0,
            color:'#274E65',
          },
          headerName:{
            width:200,
            margin: 4,
            fontSize: 8,
            flexGrow: 0,
            color:'#274E65',
          },
          headerPasscode:{
            width:100,
            margin: 4,
            fontSize: 8,
            flexGrow: 0,
            color:'#274E65',
          },
          headerAdmin:{
            width:100,
            margin: 4,
            fontSize: 8,
            flexGrow: 0,
            color:'#274E65',
          },
          headerPhone: {
            width:100,
            margin: 4,
            fontSize: 8,
            flexGrow: 0,
            color:'#274E65',
          },
          headerTerm:{
            fontSize: 10,
            marginTop:0,
          },
          buyersRights:{
            fontWeight:'bold',
            marginTop:20,
            fontSize:8,
          },
          footerParagraph:{
            marginTop:20,
            fontSize:8,
          },
          mainRow:{
            fontSize:16,
            color:'#274E65',
            fontWeight: 'bold',
            padding: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#777',
          },
          alternateRow: {
            backgroundColor: '#f0f0f0', // Light gray background
          },
  });

// Flatten the SKU arrays and count occurrences (no filtering on isService)
const skuCounts = basket.reduce((acc, item) => {
  // Ensure 'sku' is an array before iterating
  if (Array.isArray(item.sku)) {
    item.sku.forEach(sku => {
      acc[sku] = (acc[sku] || 0) + 1;
    });
  }
  return acc;
}, {});

// Generate the output for ReactPDF
const pdfOutput = Object.entries(skuCounts).map(([sku, count], index) => (
  <View 
    key={sku} 
    style={[
      styles.mainRow, 
      index % 2 === 1 ? styles.alternateRow : null // Apply gray background to every second line
    ]}
  >
    <Text>{`${count} x ${sku}`}</Text>
  </View>
));


return (
  <Document>
    <Page size="A4" style={styles.page}>
      <PDFbackgroundline/>
      <View style={styles.contentWrapper}>
        <View style={styles.headerWrapper}>
          <View style={styles.companyAddressBox}>
            <View style={styles.address}>
              <View style={styles.addressHeader}>
                <Image style={styles.image} src={SecurepolLogo} />
              </View>
              <View style={styles.addressBody}>
                <Text>300, 400 5 Ave SW</Text>
                <Text>Calgary, AB T2W3C7</Text>
                <Text>info@securepol.com</Text>
                <Text>(403) 703-1273</Text>
                <Text>Lic#: BL184235</Text>
                <Text>CPS#: 00427</Text>
              </View>
            </View>
          </View>
          <View style={styles.pdfTitleBox}>
            <View style={styles.pdfDate}>
              <Text>{displayFormattedDate()}</Text>
            </View>
            <View style={styles.pdfNumber}>
              <Text>ID: {pdfID}</Text>
            </View>
          </View>
        </View>

        <View style={styles.pdfTitleBox}>
          <Text>Purchase Order {pdfID}</Text>
        </View>

        <View style={styles.itemsListWrapper}>
          <View style={styles.headerlabel}>
            <Text>Order Details</Text>
          </View>
          <View style={styles.itemsListBody}>
            {pdfOutput} {/* Render grouped items by category and SKU */}
          </View>
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </View>
    </Page>
  </Document>
);
}
export default PDFDocument;
