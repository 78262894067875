import React, { useState, useEffect } from 'react';
import css from './EditView.module.css';
import AppMenu from '../AppCommonComponents/nav/NavigationElements/AppMenu/AppMenu';
import NavigationElementsWrapper from '../AppCommonComponents/nav/NavigationElementsWrapper/NavigationElementsWrapper';
import PriceDisplay from '../AppCommonComponents/Price/PriceDisplay/PriceDisplay';
import SelectQty from './SelectQty/SelectQty';
import SvgWhiteSquaresBottom from '../../assets/svg/backgrounds/svgWhiteSquaresBottolm';
import SvgBackground from '../../webComponents/Hooks/SvgBackground';
import { GiInfo } from "react-icons/gi";
import InfoModal from  '../../webComponents/common/InfoModal/InfoModal';

import select_qty from '../../webComponents/common/InfoModal/img/select_quantity.png'
import open_details from '../../webComponents/common/InfoModal/img/open_details.png'
import add_label from '../../webComponents/common/InfoModal/img/add_label.png'
import change_model from '../../webComponents/common/InfoModal/img/change_model.png'
import select_model from '../../webComponents/common/InfoModal/img/select_model.png'
import go_learn from '../../webComponents/common/InfoModal/img/go_learn.png'
import see_details from '../../webComponents/common/InfoModal/img/see_details.png'
import select_existing from '../../webComponents/common/InfoModal/img/select_existing.png'

const EditView = ({
    project, 
    monthly, 
    upfront, 
    setValue, 
    checkWithBasket, 
    view, 
    setView, 
    basket, 
    setBasket, 
    setModifiedModel,
    isVisible, 
    setIsVisible, 
    setComponentIndex, 
    setTypeIndex, 
    setModelIndex,
    discount, 
    resetBasket,
    tipsDisplayed,
    setTipsDisplayed
}) => {

  const [shake, setShake] = useState(false);


  const handleBasketReset = () => {
    resetBasket();
  
    const element = document.querySelector(`.${css.body}`);
    if (element) {
      // Remove the class if it's already there to restart the animation
      element.classList.remove(css.shake);
  
      // Use requestAnimationFrame to ensure the browser has removed the class before re-adding it
      requestAnimationFrame(() => {
        element.classList.add(css.shake);
      });
    }
  };

  const[showModal, setShowModal] = useState(true);

  const handleClick = () => {
      setTipsDisplayed((prevtipsDisplayed) => ({
          ...prevtipsDisplayed,
          edit: !prevtipsDisplayed.edit 
      }));
  };
  useEffect(()=>{
    if(!showModal){
        handleClick();
    }
},[showModal])

  


  const modalData = [
    { header: 'Add Components', content: 'Pick sensors, cameras, smart home devices, and extras to complete your package.', img: select_qty },

    { header: 'More Settings', content: 'Tap the three dots to see a list of your selected devices.', img: open_details },

    { header: 'Add Labels', content: 'Keep things organized by adding location labels to each device.', img: add_label },

    { header: 'Upgrade Model', content: 'Some devices have multiple models. Click on model to see what’s available.', img: change_model },

    { header: 'Choose Your Model', content: 'Switch devices or integrate existing sensors from your old system. Click "Learn More" for details.', img: select_model },

    { header: 'Learn More', content: 'Check out images and details for each device.', img: go_learn },

    { header: 'See Pictures & Details', content: 'Swipe through images or scroll down for technical info.', img: see_details },

    { header: 'Using Existing Sensors', content: 'Integrate your old alarm system’s hardwired or wireless sensors. Tap "Learn more" to check wireless sensors compatibility.', img: select_existing },

];

 

  return (
    <div className={css.sectionWrapper}>
      
      {/* Conditionally render the modal based on showInstructions */}
      {tipsDisplayed.edit && (
                <InfoModal 
                    modalData={modalData} 
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            )}
      <div className={css.body} style={SvgBackground(SvgWhiteSquaresBottom())}>
      <div className={css.controlBox}>
        <div className={css.resetBtnBox}> <button className={css.resetBtn} onClick={handleBasketReset}>Reset All</button>
        </div>
        <div className={css.icon} onClick={handleClick}>
            <GiInfo />
        </div>
      </div>
        {project.components.map((component, i) => (
          <div key={'com' + i}>
            <div className={css.category}>
                <div className={css.categoryTitle}><h2>{component.editViewSectionTitle}</h2></div>
                
                
            </div>
            <div className={css.itemsWrapper}>
              {component.type.map((type, j) => (
                <SelectQty
                  key={'type' + j}
                  project={project}
                  checkWithBasket={checkWithBasket}
                  setValue={setValue}
                  basket={basket}
                  setBasket={setBasket}
                  setModifiedModel={setModifiedModel}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  equipmentType={type}
                  setView={setView}
                  setComponentIndex={() => setComponentIndex(i)}
                  setTypeIndex={() => setTypeIndex(j)}
                  setModelIndex={setModelIndex}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className={css.footer}>
        <div className={css.priceWrapper}>
          <PriceDisplay upfront={upfront} monthly={monthly} discount={discount} />
        </div>
      </div>
      <div className={css.header}>
        <NavigationElementsWrapper>
          <AppMenu setView={setView} view={view} />
        </NavigationElementsWrapper>
      </div>
    </div>
  );
};

export default EditView;
