import d1 from './img/d1.jpg';
import d2 from './img/d2.jpg';
import d3 from './img/d3.jpg';
import { getPrice } from '../../../../Prices';

const DSHD2 = () => {
    const device = {
        title: 'Smart Doorbell',
        model: 'DS-DB2',
        modelViewImage: d1,
        image: [d1,d2,d3],
        shortDescription: 'Smart doorbell with HD video, two-way audio, and motion detection.',
        price:getPrice('EZVIZ_DOORBELL'),
        group: 'ezviz',
        checkListKeyword: ['Video'],
        infoHeader: "Smart Video Doorbell",
        spec: [
            { row1: 'Model:', row2: 'DS-HD2' },
            { row1: 'Brand:', row2: 'Hikvision' },
            { row1: 'Resolution:', row2: '2K+' },
            { row1: 'Connectivity:', row2: '2.4, 5GHz Wi-Fi' },
            { row1: 'Color:', row2: 'Black/Silver' },
            { row1: 'Mounting:', row2: 'Surface mount' },
            { row1: 'Power Supply:', row2: 'Hardwired' },
            { row1: 'Field of View:', row2: '180°' },
            { row1: 'Audio:', row2: 'Two-Way Audio' },
            { row1: 'Night Vision:', row2: 'Yes' },
            { row1: 'Motion Detection:', row2: 'Yes' },
            { row1: 'Dimensions:', row2: '4.8 x 1.8 x 1.0 inches' },
            { row1: 'Weatherproof:', row2: 'IP65' },
            { row1: 'Tamperproof:', row2: 'Yes' },
            { row1: 'Approvals and Standards:', row2: 'FCC, CE, RoHS' },
        ],
        info: [
            {
                header: 'Smart Doorbell: HD Video and Two-Way Audio',
                text: 'The Hikvision DS-HD2 smart doorbell provides high-definition video and two-way audio, ensuring clear communication and enhanced security at your front door.',
                section: [
                    { title: "2K+ Video" },
                    { text: "The DS-HD2 doorbell captures video in 1080P HD resolution, providing clear and detailed images of visitors at your door. The wide field of view ensures comprehensive coverage of the entryway." },
                    
                    { title: "Two-Way Audio" },
                    { text: "With built-in two-way audio, the doorbell allows you to communicate with visitors in real-time. The high-quality microphone and speaker ensure clear conversations." },
                    
                    { title: "Motion Detection" },
                    { text: "The doorbell features advanced motion detection technology, alerting you to movement near your front door. Customize motion detection zones and sensitivity to suit your needs." },
                    
                    { title: "Night Vision" },
                    { text: "Equipped with infrared night vision, the DS-HD2 doorbell provides clear video even in low-light conditions. This ensures reliable monitoring around the clock." },
                    
                    { title: "Wi-Fi Connectivity" },
                    { text: "The doorbell connects to your home Wi-Fi network, allowing you to access live video and recordings from your smartphone or computer. This wireless connectivity simplifies installation and ensures remote access." },
                    
                    { title: "Weatherproof Design" },
                    { text: "Designed for outdoor use, the DS-HD2 doorbell features an IP65 weatherproof rating, protecting it from rain, snow, and other environmental factors. This ensures reliable performance in various weather conditions." },
                    
                    { title: "Tamper-Resistant" },
                    { text: "The doorbell includes tamper detection features, alerting you if the device is tampered with or removed. This enhances security and ensures continuous protection." },
                    
                    { title: "Compliance and Certification" },
                    { text: "The DS-HD2 smart doorbell meets industry standards and certifications for safety and performance, including FCC, CE, and RoHS certifications." },
                ]
            },

            {
                header: 'How does it work?',
                text: 'The Hikvision DS-HD2 smart doorbell operates using advanced video, audio, and wireless communication technology to provide enhanced security and convenience at your front door.',
                section: [
                    { title: "Video and Audio Capture" },
                    { text: "The doorbell captures high-definition video and audio of visitors at your door. The wide field of view and high resolution ensure that you can see and hear clearly." },
                    
                    { title: "Motion Detection and Alerts" },
                    { text: "When motion is detected near your front door, the doorbell sends an alert to your smartphone or computer. You can customize the motion detection settings to reduce false alarms." },
                    
                    { title: "Two-Way Communication" },
                    { text: "The built-in microphone and speaker allow you to communicate with visitors in real-time. This is useful for greeting guests, providing instructions to delivery personnel, or deterring potential intruders." },
                    
                    { title: "Night Vision" },
                    { text: "Infrared night vision ensures that the doorbell provides clear video even in low-light conditions. This allows you to monitor your front door 24/7." },
                    
                    { title: "Wi-Fi Connectivity" },
                    { text: "The doorbell connects to your home Wi-Fi network, allowing you to access live video and recordings remotely. This wireless connection also simplifies installation." },
                    
                    { title: "Tamper Detection" },
                    { text: "The doorbell features tamper detection, alerting you if someone tries to remove or tamper with the device. This enhances security and ensures continuous protection." },
                ]
            },

            {
                header: 'Where to place it?',
                text: 'The placement of the Hikvision DS-HD2 smart doorbell is crucial to its effectiveness. Here are some key guidelines for where to place the doorbell:',
                section: [
                    { title: "Front Door" },
                    { text: "Install the doorbell next to your front door to monitor and communicate with visitors. Ensure that the camera has a clear view of the entryway and that the doorbell button is easily accessible." },
                    
                    { title: "Side and Back Doors" },
                    { text: "Consider installing additional doorbells at side or back doors to monitor all entry points to your home. This provides comprehensive security coverage." },
                    
                    { title: "Height and Angle" },
                    { text: "Install the doorbell at a height where it can capture clear images of visitors' faces. Adjust the angle to ensure that the camera covers the desired area without obstructions." },
                    
                    { title: "Weather Protection" },
                    { text: "Ensure that the doorbell is installed in a location that provides some protection from direct exposure to the elements, while still allowing it to function effectively in various weather conditions." },
                    
                    { title: "Wi-Fi Signal Strength" },
                    { text: "Install the doorbell in a location with a strong Wi-Fi signal to ensure reliable connectivity. If necessary, use a Wi-Fi extender to improve signal strength." },
                    
                    { title: "Secure Mounting" },
                    { text: "Ensure that the doorbell is securely mounted to prevent tampering or accidental disconnection. Use the provided mounting hardware for a stable installation." },
                ]
            },
        ]
    }
    return device;
}
export default DSHD2;
